export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const API_ERROR = "LOGIN_API_ERROR";

export const REGISTER_USER = "register_user";
export const REGISTER_USER_SUCCESSFUL = "register_user_successfull";
export const REGISTER_USER_FAILED = "register_user_failed";

// user
export const REQUEST_CURRENT_USER_SUCCESS = "REQUEST_CURRENT_USER_SUCCESS";
export const REQUEST_CURRENT_USER_FAILURE = "REQUEST_CURRENT_USER_FAILURE";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
export const INVITE_USER_FAILURE = "INVITE_USER_FAILURE";

/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH";
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME";
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE";

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME";

/* RIGHT SIDEBAR */
export const TOGGLE_RIGHT_SIDEBAR = "TOGGLE_RIGHT_SIDEBAR";
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR";
export const HIDE_RIGHT_SIDEBAR = "HIDE_RIGHT_SIDEBAR";

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER";

// integrations
export const REQUEST_INTEGRATIONS_SUCCESS = "REQUEST_INTEGRATIONS_SUCCESS";
export const REQUEST_INTEGRATIONS_FAILURE = "REQUEST_INTEGRATIONS_FAILURE";
export const UPDATE_CREDENTIALS_SUCCESS = "UPDATE_CREDENTIALS_SUCCESS";
export const UPDATE_CREDENTIALS_FAILURE = "UPDATE_CREDENTIALS_FAILURE";
export const GET_SUPPLIER_CREDENTIALS_SUCCESS =
  "GET_SUPPLIER_CREDENTIALS_SUCCESS";
export const GET_SUPPLIER_CREDENTIALS_FAILURE =
  "GET_SUPPLIER_CREDENTIALS_FAILURE";

// platforms
export const REQUEST_PLATFORMS_SUCCESS = "REQUEST_PLATFORMS_SUCCESS";
export const REQUEST_PLATFORMS_FAILURE = "REQUEST_PLATFORMS_FAILURE";

// shops
export const REQUEST_SHOPS_SUCCESS = "REQUEST_SHOPS_SUCCESS";
export const REQUEST_SHOPS_FAILURE = "REQUEST_SHOPS_FAILURE";
export const ADD_SHOP_SUCCESS = "ADD_SHOP_SUCCESS";
export const ADD_SHOP_FAILURE = "ADD_SHOP_FAILURE";
export const DELETE_SHOP_SUCCESS = "DELETE_SHOP_SUCCESS";
export const DELETE_SHOP_FAILURE = "DELETE_SHOP_FAILURE";
export const REQUEST_ETSY_AUTH_URL_SUCCESS = "REQUEST_ETSY_AUTH_URL_SUCCESS";
export const REQUEST_ETSY_AUTH_URL_FAILURE = "REQUEST_ETSY_AUTH_URL_FAILURE";
export const REQUEST_AMAZON_AUTH_URL_SUCCESS =
  "REQUEST_AMAZON_AUTH_URL_SUCCESS";
export const REQUEST_AMAZON_AUTH_URL_FAILURE =
  "REQUEST_AMAZON_AUTH_URL_FAILURE";
export const ADD_ETSY_AUTH_TOKEN_SUCCESS = "ADD_ETSY_AUTH_TOKEN_SUCCESS";
export const ADD_ETSY_AUTH_TOKEN_FAILURE = "ADD_ETSY_AUTH_TOKEN_FAILURE";
export const ADD_AMAZON_AUTH_TOKEN_SUCCESS = "ADD_AMAZON_AUTH_TOKEN_SUCCESS";
export const ADD_AMAZON_AUTH_TOKEN_FAILURE = "ADD_ETSY_AUTH_TOKEN_FAILURE";
export const ADD_SHOPIFY_AUTH_SUCCESS = "ADD_SHOPIFY_AUTH_SUCCESS";
export const ADD_SHOPIFY_AUTH_FAILURE = "ADD_SHOPIFY_AUTH_FAILURE";
export const ADD_WOOCOMMERCE_AUTH_SUCCESS = "ADD_WOOCOMMERCE_AUTH_SUCCESS";
export const ADD_WOOCOMMERCE_AUTH_FAILURE = "ADD_WOOCOMMERCE_AUTH_FAILURE";
export const REQUEST_PRODUCT_SHOPS_SUCCESS = "REQUEST_PRODUCT_SHOPS_SUCCESS";
export const REQUEST_PRODUCT_SHOPS_FAILURE = "REQUEST_PRODUCT_SHOPS_FAILURE";
export const REQUEST_ETSY_CATEGORIES_SUCCESS =
  "REQUEST_ETSY_CATEGORIES_SUCCESS";
export const REQUEST_ETSY_CATEGORIES_FAILURE =
  "REQUEST_ETSY_CATEGORIES_FAILURE";
export const REQUEST_ETSY_TEMPLATES_SUCCESS = "REQUEST_ETSY_TEMPLATES_SUCCESS";
export const REQUEST_ETSY_TEMPLATES_FAILURE = "REQUEST_ETSY_TEMPLATES_FAILURE";
export const ADD_TO_CHANNEL_SUCCESS = "ADD_TO_CHANNEL_SUCCESS";
export const ADD_TO_CHANNEL_FAILURE = "ADD_TO_CHANNEL_FAILURE";
export const ADD_SHOPIFY_TOKEN_SUCCESS = "ADD_SHOPIFY_TOKEN_SUCCESS";
export const ADD_SHOPIFY_TOKEN_FAILURE = "ADD_SHOPIFY_TOKEN_FAILURE";
export const ADD_SHOPIFY_ANONY_TOKEN_SUCCESS =
  "ADD_SHOPIFY_ANONY_TOKEN_SUCCESS";
export const ADD_SHOPIFY_ANONY_TOKEN_FAILURE =
  "ADD_SHOPIFY_ANONY_TOKEN_FAILURE";
export const REQUEST_SHOP_DETAILS_SUCCESS = "REQUEST_SHOP_DETAILS_SUCCESS";
export const REQUEST_SHOP_DETAILS_FAILURE = "REQUEST_SHOP_DETAILS_FAILURE";
export const SHIP_STATION_SUCCESS = "SHIP_STATION_SUCCESS";
export const SHIP_STATION_FAILURE = "SHIP_STATION_FAILURE";
export const SAVE_SHIP_STATION_AUTH_SUCCESS = "SAVE_SHIP_STATION_AUTH_SUCCESS";
export const SAVE_SHIP_STATION_AUTH_FAILURE = "SAVE_SHIP_STATION_AUTH_FAILURE";
export const ADD_SHOP_AUTH_SUCCESS = "ADD_SHOP_AUTH_SUCCESS";
export const ADD_SHOP_AUTH_FAILURE = "ADD_SHOP_AUTH_FAILURE";
export const UPDATE_SHOP_SUCCESS = "UPDATE_SHOP_SUCCESS";
export const UPDATE_SHOP_FAILURE = "UPDATE_SHOP_FAILURE";
export const GET_PRODUCT_OF_SHOP_SUCCESS = "GET_PRODUCT_OF_SHOP_SUCCESS";
export const GET_PRODUCT_OF_SHOP_FAILURE = "GET_PRODUCT_OF_SHOP_FAILURE";
export const UPDATE_PRODUCT_VISIBILITY_SUCCESS =
  "UPDATE_PRODUCT_VISIBILITY_SUCCESS";
export const UPDATE_PRODUCT_VISIBILITY_FAILURE =
  "UPDATE_PRODUCT_VISIBILITY_FAILURE";
export const UPDATE_SHOP_HOME_PAGE_SETTINGS_SUCCESS =
  "UPDATE_SHOP_HOME_PAGE_SETTINGS_SUCCESS";
export const UPDATE_SHOP_HOME_PAGE_SETTINGS_FAILURE =
  "UPDATE_SHOP_HOME_PAGE_SETTINGS_FAILURE";
export const GET_SHOP_HOME_PAGE_SETTINGS_SUCCESS =
  "GET_SHOP_HOME_PAGE_SETTINGS_SUCCESS";
export const GET_SHOP_HOME_PAGE_SETTINGS_FAILURE =
  "GET_SHOP_HOME_PAGE_SETTINGS_FAILURE";
export const CONNECT_SHOP_SUCCESS = "CONNECT_SHOP_SUCCESS";
export const CONNECT_SHOP_FAILURE = "CONNECT_SHOP_FAILURE";

// suppliers
export const REQUEST_SUPPLIERS_SUCCESS = "REQUEST_SUPPLIERS_SUCCESS";
export const REQUEST_SUPPLIERS_FAILURE = "REQUEST_SUPPLIERS_FAILURE";
export const REQUEST_LOCATIONS_OF_A_SUPPLIER_SUCCESS =
  "REQUEST_LOCATIONS_OF_A_SUPPLIER_SUCCESS";
export const REQUEST_LOCATIONS_OF_A_SUPPLIER_FAILURE =
  "REQUEST_LOCATIONS_OF_A_SUPPLIER_FAILURE";
export const UPDATE_SUPPLIER_LOCATION_SUCCESS =
  "UPDATE_SUPPLIER_LOCATION_SUCCESS";
export const UPDATE_SUPPLIER_LOCATION_FAILURE =
  "UPDATE_SUPPLIER_LOCATION_FAILURE";
export const REQUEST_SQUARE_AUTH_URL_SUCCESS =
  "REQUEST_SQUARE_AUTH_URL_SUCCESS";
export const REQUEST_SQUARE_AUTH_URL_FAILURE =
  "REQUEST_SQUARE_AUTH_URL_FAILURE";
export const SQUARE_CALLBACK_SUCCESS = "SQUARE_CALLBACK_SUCCESS";
export const SQUARE_CALLBACK_FAILURE = "SQUARE_CALLBACK_FAILURE";

//blankProducts
export const REQUEST_BLANK_PRODUCT_SUCCESS = "REQUEST_BLANK_PRODUCT_SUCCESS";
export const REQUEST_BLANK_PRODUCT_FAILURE = "REQUEST_BLANK_PRODUCT_FAILURE";
export const REQUEST_NEW_BLANK_PRODUCT_SUCCESS =
  "REQUEST_BLANK_PRODUCT_SUCCESS";
export const REQUEST_NEW_BLANK_PRODUCT_FAILURE =
  "REQUEST_BLANK_PRODUCT_FAILURE";
export const ADD_OR_UPDATE_BLANK_PRODUCT_SUCCESS =
  "ADD_OR_UPDATE_BLANK_PRODUCT_SUCCESS";
export const ADD_OR_UPDATE_BLANK_PRODUCT_FAILURE =
  "ADD_OR_UPDATE_BLANK_PRODUCT_FAILURE";
export const DELETE_BLANK_PRODUCT_SUCCESS = "DELETE_BLANK_PRODUCT_SUCCESS";
export const DELETE_BLANK_PRODUCT_FAILURE = "DELETE_BLANK_PRODUCT_FAILURE";
export const REQUEST_BLANK_PRODUCTS_ADDED_BY_ADMIN_SUCCESS =
  "REQUEST_BLANK_PRODUCTS_ADDED_BY_ADMIN_SUCCESS";
export const REQUEST_BLANK_PRODUCTS_ADDED_BY_ADMIN_FAILURE =
  "REQUEST_BLANK_PRODUCTS_ADDED_BY_ADMIN_FAILURE";
export const GET_BLANKSETTINGS_AND_PRICING_INFO_SUCCESS =
  "GET_BLANKSETTINGS_AND_PRICING_INFO_SUCCESS";
export const GET_BLANKSETTINGS_AND_PRICING_INFO_FAILURE =
  "GET_BLANKSETTINGS_AND_PRICING_INFO_FAILURE";
export const SAVE_BLANK__PRODUCT_PLACEMENT_DETAILS_SUCCESS =
  "SAVE_BLANK__PRODUCT_PLACEMENT_DETAILS_SUCCESS";
export const SAVE_BLANK__PRODUCT_PLACEMENT_DETAILS_FAILURE =
  "SAVE_BLANK__PRODUCT_PLACEMENT_DETAILS_FAILURE";
export const GET_ENABLED_BLANK_PRODUCTS_SUCCESS =
  "GET_ENABLED_BLANK_PRODUCTS_SUCCESS";
export const GET_ENABLED_BLANK_PRODUCTS_FAILURE =
  "GET_ENABLED_BLANK_PRODUCTS_FAILURE";
export const GET_ENABLED_BLANK_PRODUCT_DETAILS_SUCCESS =
  "GET_ENABLED_BLANK_PRODUCT_DETAILS_SUCCESS";
export const GET_ENABLED_BLANK_PRODUCT_DETAILS_FAILURE =
  "GET_ENABLED_BLANK_PRODUCT_DETAILS_FAILURE";
export const SAVE_NEW_PRODUCT_PLACEMENT_DETAILS_SUCCESS =
  "SAVE_NEW_PRODUCT_PLACEMENT_DETAILS_SUCCESS";
export const SAVE_NEW_PRODUCT_PLACEMENT_DETAILS_FAILURE =
  "SAVE_NEW_PRODUCT_PLACEMENT_DETAILS_FAILURE";
export const REQUEST_BIGCARTEL_AUTH_URL_SUCCESS =
  "REQUEST_BIGCARTEL_AUTH_URL_SUCCESS";
export const REQUEST_BIGCARTEL_AUTH_URL_FAILURE =
  "REQUEST_BIGCARTEL_AUTH_URL_FAILURE";
export const ADD_BIGCARTEL_AUTH_TOKEN_SUCCESS =
  "ADD_BIGCARTEL_AUTH_TOKEN_SUCCESS";
export const ADD_BIGCARTEL_AUTH_TOKEN_FAILURE =
  "ADD_BIGCARTEL_AUTH_TOKEN_FAILURE";
export const UPDATE_BACKGROUND_IMAGES_SUCCESS =
  "UPDATE_BACKGROUND_IMAGES_SUCCESS";
export const UPDATE_BACKGROUND_IMAGES_FAILURE =
  "UPDATE_BACKGROUND_IMAGES_FAILURE";

// SQUARESPACE
export const REQUEST_SQUARESPACE_AUTH_URL_SUCCESS =
  "REQUEST_SQUARESPACE_AUTH_URL_SUCCESS";
export const REQUEST_SQUARESPACE_AUTH_URL_FAILURE =
  "REQUEST_SQUARESPACE_AUTH_URL_FAILURE";
export const ADD_SQUARESPACE_AUTH_TOKEN_SUCCESS =
  "ADD_SQUARESPACE_AUTH_TOKEN_SUCCESS";
export const ADD_SQUARESPACE_AUTH_TOKEN_FAILURE =
  "ADD_SQUARESPACE_AUTH_TOKEN_FAILURE";

// EBAY
export const REQUEST_EBAY_AUTH_URL_SUCCESS = "REQUEST_EBAY_AUTH_URL_SUCCESS";
export const REQUEST_EBAY_AUTH_URL_FAILURE = "REQUEST_EBAY_AUTH_URL_FAILURE";
export const ADD_EBAY_AUTH_TOKEN_SUCCESS = "ADD_EBAY_AUTH_TOKEN_SUCCESS";
export const ADD_EBAY_AUTH_TOKEN_FAILURE = "ADD_EBAY_AUTH_TOKEN_FAILURE";
// products
export const REQUEST_AND_RESET_PRODUCTS_SUCCESS =
  "REQUEST_AND_RESET_PRODUCTS_SUCCESS";
export const REQUEST_AND_RESET_PRODUCTS_FAILURE =
  "REQUEST_AND_RESET_PRODUCTS_FAILURE";
export const REQUEST_PRODUCTS_SUCCESS = "REQUEST_PRODUCTS_SUCCESS";
export const REQUEST_PRODUCTS_FAILURE = "REQUEST_PRODUCTS_FAILURE";
export const DELETE_PRODUCTS_SUCCESS = "DELETE_PRODUCTS_SUCCESS";
export const DELETE_PRODUCTS_FAILURE = "DELETE_PRODUCTS_FAILURE";
export const REQUEST_PRODUCTS_COUNT_SUCCESS = "REQUEST_PRODUCTS_COUNT_SUCCESS";
export const REQUEST_PRODUCTS_COUNT_FAILURE = "REQUEST_PRODUCTS_COUNT_FAILURE";
export const REQUEST_PRODUCTS_DETAILS_SUCCESS =
  "REQUEST_PRODUCTS_DETAILS_SUCCESS";
export const REQUEST_PRODUCTS_DETAILS_FAILURE =
  "REQUEST_PRODUCTS_DETAILS_FAILURE";
export const REQUEST_PRODUCT_MAPPING_SUCCESS =
  "REQUEST_PRODUCT_MAPPING_SUCCESS";
export const REQUEST_PRODUCT_MAPPING_FAILURE =
  "REQUEST_PRODUCT_MAPPING_FAILURE";
export const REQUEST_PRODUCT_MAPPING_IMAGES_SUCCESS =
  "REQUEST_PRODUCT_MAPPING_IMAGES_SUCCESS";
export const REQUEST_PRODUCT_MAPPING_IMAGES_FAILURE =
  "REQUEST_PRODUCT_MAPPING_IMAGES_FAILURE";
export const SAVE_PRODUCT_MAPPING_SUCCESS = "SAVE_PRODUCT_MAPPING_SUCCESS";
export const SAVE_PRODUCT_MAPPING_FAILURE = "SAVE_PRODUCT_MAPPING_FAILURE";
export const POST_MANUAL_PRODUCT_SUCCESS = "POST_MANUAL_PRODUCT_SUCCESS";
export const POST_MANUAL_PRODUCT_FAILURE = "POST_MANUAL_PRODUCT_FAILURE";
export const POST_MANUAL_CSV_PRODUCT_SUCCESS =
  "POST_MANUAL_CSV_PRODUCT_SUCCESS";
export const POST_MANUAL_CSV_PRODUCT_FAILURE =
  "POST_MANUAL_CSV_PRODUCT_FAILURE";
export const REQUEST_MANUAL_PRODUCT_SUCCESS = "REQUEST_MANUAL_PRODUCT_SUCCESS";
export const REQUEST_MANUAL_PRODUCT_FAILURE = "REQUEST_MANUAL_PRODUCT_FAILURE";
export const SET_DISCOUNT_PRICE_AND_PLACEMENT_SUCCESS =
  "SET_DISCOUNT_PRICE_AND_PLACEMENT_SUCCESS";
export const SET_DISCOUNT_PRICE_AND_PLACEMENT_FAILURE =
  "SET_DISCOUNT_PRICE_AND_PLACEMENT_FAILURE";
export const REQUEST_BLANK_PRODUCT_COLORS_SUCCESS =
  "REQUEST_BLANK_PRODUCT_COLORS_SUCCESS";
export const REQUEST_BLANK_PRODUCT_COLORS_FAILURE =
  "REQUEST_BLANK_PRODUCT_COLORS_FAILURE";
export const SAVE_LISTING_MAPPING_TO_DRAFT_SUCCESS =
  "SAVE_LISTING_MAPPING_TO_DRAFT_SUCCESS";
export const SAVE_LISTING_MAPPING_TO_DRAFT_FAILURE =
  "SAVE_LISTING_MAPPING_TO_DRAFT_FAILURE";
export const GET_LISTING_MAPPING_TO_DRAFT_SUCCESS =
  "GET_LISTING_MAPPING_TO_DRAFT_SUCCESS";
export const GET_LISTING_MAPPING_TO_DRAFT_FAILURE =
  "GET_LISTING_MAPPING_TO_DRAFT_FAILURE";
export const SAVE_LISTING_TEMPLATE_SUCCESS = "SAVE_LISTING_TEMPLATE_SUCCESS";
export const SAVE_LISTING_TEMPLATE_FAILURE = "SAVE_LISTING_TEMPLATE_FAILURE";
export const GET_LISTING_TEMPLATE_SUCCESS = "GET_LISTING_TEMPLATE_SUCCESS";
export const GET_LISTING_TEMPLATE_FAILURE = "GET_LISTING_TEMPLATE_FAILURE";
export const DELETE_LISTING_TEMPLATE_SUCCESS =
  "DELETE_LISTING_TEMPLATE_SUCCESS";
export const DELETE_LISTING_TEMPLATE_FAILURE =
  "DELETE_LISTING_TEMPLATE_FAILURE";
export const IMPORT_PRODUCT_SUCCESS = "IMPORT_PRODUCT_SUCCESS";
export const IMPORT_PRODUCT_FAILURE = "IMPORT_PRODUCT_FAILURE";
export const SYNC_LISTINGS_SUCCESS = "SYNC_LISTINGS_SUCCESS";
export const SYNC_LISTINGS_FAILURE = "SYNC_LISTINGS_FAILURE";
export const UPDATE_PREVIEW_IMAGES = "UPDATE_PREVIEW_IMAGES";
export const UPDATE_CANVAS_IMAGES = "UPDATE_CANVAS_IMAGES";
export const SET_SELECTED_FILTER_FOR_LISTING =
  "SET_SELECTED_FILTER_FOR_LISTING";
export const SET_LISTING_LAST_ID = "SET_LISTING_LAST_ID";
export const REQUEST_MANUAL_PRODUCT_DETAILS_SUCCESS =
  "REQUEST_MANUAL_PRODUCT_DETAILS_SUCCESS";
export const REQUEST_MANUAL_PRODUCT_DETAILS_FAILURE =
  "REQUEST_MANUAL_PRODUCT_DETAILS_FAILURE";
export const GET_ALL_VARIANTS_COST_SUCCESS = "GET_ALL_VARIANTS_COST_SUCCESS";
export const GET_ALL_VARIANTS_COST_FAILURE = "GET_ALL_VARIANTS_COST_FAILURE";
export const GET_SINGLE_VARIANT_COST_SUCCESS =
  "GET_SINGLE_VARIANT_COST_SUCCESS";
export const GET_SINGLE_VARIANT_COST_FAILURE =
  "GET_SINGLE_VARIANT_COST_FAILURE";

// storage
export const REQUEST_FILE_SUCCESS = "REQUEST_FILE_SUCCESS";
export const REQUEST_FILE_FAILURE = "REQUEST_FILE_FAILURE";
export const ADD_FILE_SUCCESS = "ADD_FILE_SUCCESS";
export const ADD_FILE_FAILURE = "ADD_FILE_FAILURE";
export const GET_ALL_FILES_SUCCESS = "GET_ALL_FILES_SUCCESS";
export const GET_ALL_FILES_FAILURE = "GET_ALL_FILES_FAILURE";

// settings
export const REQUEST_PLACEMENTS_SUCCESS = "REQUEST_PLACEMENTS_SUCCESS";
export const REQUEST_PLACEMENTS_FAILURE = "REQUEST_PLACEMENTS_FAILURE";
export const REQUEST_TOURS_SUCCESS = "REQUEST_TOURS_SUCCESS";
export const REQUEST_TOURS_FAILURE = "REQUEST_TOURS_FAILURE";
export const UPDATE_TOURS_SUCCESS = "UPDATE_TOURS_SUCCESS";
export const UPDATE_TOURS_FAILURE = "UPDATE_TOURS_FAILURE";
export const LOADING_TOURS = "LOADING_TOURS";
export const REQUEST_PRINT_MODES_SUCCESS = "REQUEST_PRINT_MODES_SUCCESS";
export const REQUEST_PRINT_MODES_FAILURE = "REQUEST_PRINT_MODES_FAILURE";
export const REQUEST_THEMES_SUCCESS = "REQUEST_THEMES_SUCCESS";
export const REQUEST_THEMES_FAILURE = "REQUEST_THEMES_FAILURE";
export const ADD_THEMES_SUCCESS = "ADD_THEMES_SUCCESS";
export const ADD_THEMES_FAILURE = "ADD_THEMES_FAILURE";
export const CREATE_ENTERPRISE_SUCCESS = "CREATE_ENTERPRISE_SUCCESS";
export const CREATE_ENTERPRISE_FAILURE = "CREATE_ENTERPRISE_FAILURE";
export const EDIT_ENTERPRISE_SUCCESS = "EDIT_ENTERPRISE_SUCCESS";
export const EDIT_ENTERPRISE_FAILURE = "EDIT_ENTERPRISE_FAILURE";
export const REQUEST_MAX_PRINT_SIZES_SUCCESS =
  "REQUEST_MAX_PRINT_SIZES_SUCCESS";
export const REQUEST_MAX_PRINT_SIZES_FAILURE =
  "REQUEST_MAX_PRINT_SIZES_FAILURE";
export const GET_PRICING_TEMPLATES_SUCCESS = "GET_PRICING_TEMPLATES_SUCCESS";
export const GET_PRICING_TEMPLATES_FAILURE = "GET_PRICING_TEMPLATES_FAILURE";
export const ADD_PRICING_TEMPLATES_SUCCESS = "ADD_PRICING_TEMPLATES_SUCCESS";
export const ADD_PRICING_TEMPLATES_FAILURE = "ADD_PRICING_TEMPLATES_FAILURE";
export const EDIT_PRICING_TEMPLATES_SUCCESS = "EDIT_PRICING_TEMPLATES_SUCCESS";
export const EDIT_PRICING_TEMPLATES_FAILURE = "EDIT_PRICING_TEMPLATES_FAILURE";
export const DELETE_PRICING_TEMPLATES_SUCCESS =
  "DELETE_PRICING_TEMPLATES_SUCCESS";
export const DELETE_PRICING_TEMPLATES_FAILURE =
  "DELETE_PRICING_TEMPLATES_FAILURE";
export const GET_ORDER_STATUS_COLOR_TAGS_SUCCESS =
  "GET_ORDER_STATUS_COLOR_TAGS_SUCCESS";
export const GET_ORDER_STATUS_COLOR_TAGS_FAILURE =
  "GET_ORDER_STATUS_COLOR_TAGS_FAILURE";
export const ADD_ORDER_STATUS_COLOR_TAGS_SUCCESS =
  "ADD_ORDER_STATUS_COLOR_TAGS_SUCCESS";
export const ADD_ORDER_STATUS_COLOR_TAGS_FAILURE =
  "ADD_ORDER_STATUS_COLOR_TAGS_FAILURE";
export const GET_USER_SETTINGS_SUCCESS = "GET_USER_SETTINGS_SUCCESS";
export const GET_USER_SETTINGS_FAILURE = "GET_USER_SETTINGS_FAILURE";
export const SAVE_USER_SETTINGS_SUCCESS = "SAVE_USER_SETTINGS_SUCCESS";
export const SAVE_USER_SETTINGS_FAILURE = "SAVE_USER_SETTINGS_FAILURE";
export const SAVE_SHIPPING_UPCHARGE_SUCCESS = "SAVE_SHIPPING_UPCHARGE_SUCCESS";
export const SAVE_SHIPPING_UPCHARGE_FAILURE = "SAVE_SHIPPING_UPCHARGE_FAILURE";
export const GET_SHIPPING_UPCHARGE_SUCCESS = "GET_SHIPPING_UPCHARGE_SUCCESS";
export const GET_SHIPPING_UPCHARGE_FAILURE = "GET_SHIPPING_UPCHARGE_FAILURE";

// items
export const REQUEST_ITEMS_SUCCESS = "REQUEST_ITEMS_SUCCESS";
export const REQUEST_ITEMS_FAILURE = "REQUEST_ITEMS_FAILURE";
export const GET_FILTERED_ITEMS = "GET_FILTERED_ITEMS";
export const REQUEST_FILTERED_ITEMS_SUCCESS = "REQUEST_FILTERED_ITEMS_SUCCESS";
export const REQUEST_FILTERED_ITEMS_FAILURE = "REQUEST_FILTERED_ITEMS_FAILURE";
export const SHOW_LOADING_FALSE = "  SHOW_LOADING_FALSE";
export const SHOW_LOADING_TRUE = "  SHOW_LOADING_TRUE";
export const BATCH_BY_ORDER_SUCCESS = "BATCH_BY_ORDER_SUCCESS";

// batches
export const REQUEST_BATCHES_SUCCESS = "REQUEST_BATCHES_SUCCESS";
export const REQUEST_BATCHES_FAILURE = "REQUEST_BATCHES_FAILURE";
export const REQUEST_BATCH_DETAILS_SUCCESS = "REQUEST_BATCH_DETAILS_SUCCESS";
export const REQUEST_BATCH_DETAILS_FAILURE = "REQUEST_BATCH_DETAILS_FAILURE";
export const CREATE_BATCH_SUCCESS = "CREATE_BATCH_SUCCESS";
export const CREATE_BATCH_FAILURE = "CREATE_BATCH_FAILURE";
export const REQUEST_BATCH_ITEMS_SUCCESS = "REQUEST_BATCH_ITEMS_SUCCESS";
export const REQUEST_BATCH_ITEMS_FAILURE = "REQUEST_BATCH_ITEMS_FAILURE";
export const REQUEST_BATCH_BARCODES_SUCCESS = "REQUEST_BATCH_BARCODES_SUCCESS";
export const REQUEST_BATCH_BARCODES_FAILURE = "REQUEST_BATCH_BARCODES_FAILURE";
export const REQUEST_BATCH_ITEM_DETAILS_SUCCESS =
  "REQUEST_BATCH_ITEM_DETAILS_SUCCESS";
export const REQUEST_BATCH_ITEM_DETAILS_FAILURE =
  "REQUEST_BATCH_ITEM_DETAILS_FAILURE";
export const READY_TO_SHIP_SUCCESS = "READY_TO_SHIP_SUCCESS";
export const READY_TO_SHIP_FAILURE = "READY_TO_SHIP_FAILURE";
export const REQUEST_BATCH_ITEMS_COUNT_SUCCESS =
  "REQUEST_BATCH_ITEMS_COUNT_SUCCESS";
export const REQUEST_BATCH_ITEMS_COUNT_FAILURE =
  "REQUEST_BATCH_ITEMS_COUNT_FAILURE";
export const GET_ORDER_ITEMS_COUNT_SUCCESS = "GET_ORDER_ITEMS_COUNT_SUCCESS";
export const GET_ORDER_ITEMS_COUNT_FAILURE = "GET_ORDER_ITEMS_COUNT_FAILURE";
export const ZIP_IMAGES_SUCCESS = "ZIP_IMAGES_SUCCESS";
export const ZIP_IMAGES_FAILURE = "ZIP_IMAGES_FAILURE";
export const FETCH_BATCH_XML_DETAILS_SUCCESS =
  "FETCH_BATCH_XML_DETAILS_SUCCESS";
export const FETCH_BATCH_XML_DETAILS_FAILURE =
  "FETCH_BATCH_XML_DETAILS_FAILURE";
export const UNBATCH_SUCCESS = "UNBATCH_SUCCESS";
export const UNBATCH_FAILURE = "UNBATCH_FAILURE";
export const UNBATCH_ENTIRE_BATCH_SUCCESS = "UNBATCH_ENTIRE_BATCH_SUCCESS";
export const UNBATCH_ENTIRE_BATCH_FAILURE = "UNBATCH_ENTIRE_BATCH_FAILURE";
export const UPDATE_BATCH_SUCCESS = "UPDATE_BATCH_SUCCESS";
export const UPDATE_BATCH_FAILURE = "UPDATE_BATCH_FAILURE";
export const REQUEST_QUALITY_CONTROL_FAILED_ITEMS_SUCCESS =
  "REQUEST_QUALITY_CONTROL_FAILED_ITEMS_SUCCESS";
export const REQUEST_QUALITY_CONTROL_FAILED_ITEMS_FAILURE =
  "REQUEST_QUALITY_CONTROL_FAILED_ITEMS_FAILURE";
export const ARCHIVE_BATCH_SUCCESS = "ARCHIVE_BATCH_SUCCESS";
export const ARCHIVE_BATCH_FAILURE = "ARCHIVE_BATCH_FAILURE";
export const REQUEST_AQC_CHECKS_SUCCESS = "REQUEST_AQC_CHECKS_SUCCESS";
export const REQUEST_AQC_CHECKS_FAILURE = "REQUEST_AQC_CHECKS_FAILURE";
export const CREATE_AQC_MISMATCH_ITEM_SUCCESS =
  "CREATE_AQC_MISMATCH_ITEM_SUCCESS";
export const CREATE_AQC_MISMATCH_ITEM_FAILURE =
  "CREATE_AQC_MISMATCH_ITEM_FAILURE";
export const REQUEST_AQC_MISMATCH_ITEMS_SUCCESS =
  "REQUEST_AQC_MISMATCH_ITEMS_SUCCESS";
export const REQUEST_AQC_MISMATCH_ITEMS_FAILURE =
  "REQUEST_AQC_MISMATCH_ITEMS_FAILURE";
export const POST_IMAGE_PROCESSING_OPTIONS_SUCCESS =
  "POST_IMAGE_PROCESSING_OPTIONS_SUCCESS";
export const POST_IMAGE_PROCESSING_OPTIONS_FAILURE =
  "POST_IMAGE_PROCESSING_OPTIONS_FAILURE";
export const REPLACE_BATCH_ITEM_PRINT_FILE_SUCCESS =
  "REPLACE_BATCH_ITEM_PRINT_FILE_SUCCESS";
export const REPLACE_BATCH_ITEM_PRINT_FILE_FAILURE =
  "REPLACE_BATCH_ITEM_PRINT_FILE_FAILURE";

// orders
export const REQUEST_ORDERS_SUCCESS = "REQUEST_ORDERS_SUCCESS";
export const REQUEST_ORDERS_FAILURE = "REQUEST_ORDERS_FAILURE";
export const REQUEST_ORDERS_DETAILS_SUCCESS = "REQUEST_ORDERS_DETAILS_SUCCESS";
export const REQUEST_ORDERS_DETAILS_FAILURE = "REQUEST_ORDERS_DETAILS_FAILURE";
export const REQUEST_ITEMS_HISTORY_SUCCESS = "REQUEST_ITEMS_HISTORY_SUCCESS";
export const REQUEST_ITEMS_HISTORY_FAILURE = "REQUEST_ITEMS_HISTORY_FAILURE";
export const SYNC_ORDERS_SUCCESS = "SYNC_ORDERS_SUCCESS";
export const SYNC_ORDERS_FAILURE = "SYNC_ORDERS_FAILURE";
export const REQUEST_INITIAL_ORDERS_SUCCESS = "REQUEST_INITIAL_ORDERS_SUCCESS";
export const REQUEST_INITIAL_ORDERS_FAILURE = "REQUEST_INITIAL_ORDERS_FAILURE";
export const SET_LAST_ORDER_ID = "SET_LAST_ORDER_ID";
export const SET_LAST_DRAFT_ORDER_ID = "SET_LAST_DRAFT_ORDER_ID";
export const REQUEST_ORDER_DETAILS_SUCCESS = "REQUEST_ORDER_DETAILS_SUCCESS";
export const REQUEST_ORDER_DETAILS_FAILURE = "REQUEST_ORDER_DETAILS_FAILURE";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAILURE = "UPDATE_ORDER_FAILURE";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const UPDATE_SHIPPING_SPEED = "UPDATE_SHIPPING_SPEED";
export const DELETE_ORDER_FAILURE = "DELETE_ORDER_FAILURE";
export const UPDATE_DRAFT_ORDER_SUCCESS = "UPDATE_DRAFT_ORDER_SUCCESS";
export const UPDATE_DRAFT_ORDER_FAILURE = "UPDATE_DRAFT_ORDER_FAILURE";
export const UPDATE_ADMIN_DRAFT_ORDER_SUCCESS =
  "UPDATE_ADMIN_DRAFT_ORDER_SUCCESS";
export const UPDATE_ADMIN_DRAFT_ORDER_FAILURE =
  "UPDATE_ADMIN_DRAFT_ORDER_FAILURE";
export const GET_EDITABLE_PRODUCT_ORDER_ITEMS_SUCCESS =
  "GET_EDITABLE_PRODUCT_ORDER_ITEMS_SUCCESS";
export const GET_EDITABLE_PRODUCT_ORDER_ITEMS_FAILURE =
  "GET_EDITABLE_PRODUCT_ORDER_ITEMS_FAILURE";
export const EDIT_ORDER_ITEM_SUCCESS = "EDIT_ORDER_ITEM_SUCCESS";
export const EDIT_ORDER_ITEM_FAILURE = "EDIT_ORDER_ITEM_FAILURE";
export const SAVE_CSV_ORDER_SUCCESS = "SAVE_CSV_ORDER_SUCCESS";
export const SAVE_CSV_ORDER_FAILURE = "SAVE_CSV_ORDER_FAILURE";
export const SAVE_MANUAL_ORDER_SUCCESS = "SAVE_MANUAL_ORDER_SUCCESS";
export const SAVE_MANUAL_ORDER_FAILURE = "SAVE_MANUAL_ORDER_FAILURE";
export const REQUEST_ORDER_ITEM_LOCATIONS_SUCCESS =
  "REQUEST_ORDER_ITEM_LOCATIONS_SUCCESS";
export const REQUEST_ORDER_ITEM_LOCATIONS_FAILURE =
  "REQUEST_ORDER_ITEM_LOCATIONS_FAILURE";
export const GET_ORDER_WEIGHT_SUCCESS = "GET_ORDER_WEIGHT_SUCCESS";
export const GET_ORDER_WEIGHT_FAILURE = "GET_ORDER_WEIGHT_FAILURE";
export const GET_ORDER_ITEM_DETAILS_SUCCESS = "GET_ORDER_ITEM_DETAILS_SUCCESS";
export const GET_ORDER_ITEM_DETAILS_FAILURE = "GET_ORDER_ITEM_DETAILS_FAILURE";
export const REQUEST_ORDERS_COUNT_SUCCESS = "REQUEST_ORDERS_COUNT_SUCCESS";
export const REQUEST_ORDERS_COUNT_FAILURE = "REQUEST_ORDERS_COUNT_FAILURE";
export const ADD_AUTO_DIGITIZEED_FILES_SUCCESS =
  "ADD_AUTO_DIGITIZEED_FILES_SUCCESS";
export const ADD_AUTO_DIGITIZEED_FILES_FAILURE =
  "ADD_AUTO_DIGITIZEED_FILES_FAILURE";
export const GET_AUTO_DIGITIZEED_FILES_SUCCESS =
  "GET_AUTO_DIGITIZEED_FILES_SUCCESS";
export const GET_AUTO_DIGITIZEED_FILES_FAILURE =
  "GET_AUTO_DIGITIZEED_FILES_FAILURE";

// purchaseOrders
export const REQUEST_PURCHASE_ORDER_DETAIL_SUCCESS =
  "REQUEST_PURCHASE_ORDER_DETAIL_SUCCESS";
export const REQUEST_PURCHASE_ORDER_DETAIL_FAILURE =
  "REQUEST_PURCHASE_ORDER_DETAIL_FAILURE";

export const REQUEST_PURCHASE_ORDERS_SUCCESS =
  "REQUEST_PURCHASE_ORDERS_SUCCESS";
export const REQUEST_PURCHASE_ORDERS_FAILURE =
  "REQUEST_PURCHASE_ORDERS_FAILURE";
export const REQUEST_PURCHASE_ORDER_ITEMS_SUCCESS =
  "REQUEST_PURCHASE_ORDER_ITEMS_SUCCESS";
export const REQUEST_PURCHASE_ORDER_ITEMS_FAILURE =
  "REQUEST_PURCHASE_ORDER_ITEMS_FAILURE";
export const REQUEST_PRODUCT_OF_GTIN_SUCCESS =
  "REQUEST_PRODUCT_OF_GTIN_SUCCESS";
export const REQUEST_PRODUCT_OF_GTIN_FAILURE =
  "REQUEST_PRODUCT_OF_GTIN_FAILURE";
export const UPDATE_PURCHASE_ORDER_PRODUCT = "UPDATE_PURCHASE_ORDER_PRODUCT";
export const CREATE_PURCHASE_ORDER_SUCCESS = "CREATE_PURCHASE_ORDER_SUCCESS";
export const CREATE_PURCHASE_ORDER_FAILURE = "CREATE_PURCHASE_ORDER_FAILURE";
export const MARK_AS_RECEIVED_SUCCESS = "MARK_AS_RECEIVED_SUCCESS";
export const MARK_AS_RECEIVED_FAILURE = "MARK_AS_RECEIVED_FAILURE";
export const REQUEST_NEW_PURCHASE_ORDER_ITEMS_SUCCESS =
  "REQUEST_NEW_PURCHASE_ORDER_ITEMS_SUCCESS";
export const REQUEST_NEW_PURCHASE_ORDER_ITEMS_FAILURE =
  "REQUEST_NEW_PURCHASE_ORDER_ITEMS_FAILURE";
export const UPDATE_PURCHASE_ORDER_PRODUCT_QUANTITY =
  "UPDATE_PURCHASE_ORDER_PRODUCT_QUANTITY";
export const REQUEST_PURCHASE_ORDER_ITEMS_INVENTORY_SUCCESS =
  "REQUEST_PURCHASE_ORDER_ITEMS_INVENTORY_SUCCESS";
export const REQUEST_PURCHASE_ORDER_ITEMS_INVENTORY_FAILURE =
  "REQUEST_PURCHASE_ORDER_ITEMS_INVENTORY_FAILURE";

export const REQUEST_REMOVE_PURCHASE_ORDER_ITEM =
  "REQUEST_REMOVE_PURCHASE_ORDER_ITEM";

export const SEND_PURCHASE_ORDER_SUCCESS = "SEND_PURCHASE_ORDER_SUCCESS";
export const SEND_PURCHASE_ORDER_FAILURE = "SEND_PURCHASE_ORDER_FAILURE";

// addresses
export const REQUEST_ADDRESSES_SUCCESS = "REQUEST_ADDRESSES_SUCCESS";
export const REQUEST_ADDRESSES_FAILURE = "REQUEST_ADDRESSES_FAILURE";
export const REQUEST_SUPPLIERS_LOCATIONS_SUCCESS =
  "REQUEST_SUPPLIERS_LOCATIONS_SUCCESS";
export const REQUEST_SUPPLIERS_LOCATIONS_FAILURE =
  "REQUEST_SUPPLIERS_LOCATIONS_FAILURE";
export const CREATE_ADDRESS_SUCCESS = "CREATE_ADDRESS_SUCCESS";
export const CREATE_ADDRESS_FAILURE = "CREATE_ADDRESS_FAILURE";
export const REQUEST_DRAFT_ORDERS_SUCCESS = "REQUEST_DRAFT_ORDERS_SUCCESS";
export const REQUEST_DRAFT_ORDERS_FAILURE = "REQUEST_DRAFT_ORDERS_FAILURE";
export const REQUEST_ADMIN_DRAFT_ORDERS_SUCCESS =
  "REQUEST_ADMIN_DRAFT_ORDERS_SUCCESS";
export const REQUEST_ADMIN_DRAFT_ORDERS_FAILURE =
  "REQUEST_ADMIN_DRAFT_ORDERS_FAILURE";
export const REQUEST_SHARED_DRAFT_ORDERS_SUCCESS =
  "REQUEST_SHARED_DRAFT_ORDERS_SUCCESS";
export const REQUEST_SHARED_DRAFT_ORDERS_FAILURE =
  "REQUEST_SHARED_DRAFT_ORDERS_FAILURE";
export const REQUEST_INITIAL_DRAFT_ORDERS_SUCCESS =
  "REQUEST_INITIAL_DRAFT_ORDERS_SUCCESS";
export const REQUEST_INITIAL_DRAFT_ORDERS_FAILURE =
  "REQUEST_INITIAL_DRAFT_ORDERS_FAILURE";
export const REQUEST_INITIAL_UNSYNCED_ORDERS_SUCCESS =
  "REQUEST_INITIAL_UNSYNCED_ORDERS_SUCCESS";
export const REQUEST_INITIAL_UNSYNCED_ORDERS_FAILURE =
  "REQUEST_INITIAL_UNSYNCED_ORDERS_FAILURE";
export const REQUEST_UNSYNCED_ORDERS_SUCCESS =
  "REQUEST_UNSYNCED_ORDERS_SUCCESS";
export const REQUEST_UNSYNCED_ORDERS_FAILURE =
  "REQUEST_UNSYNCED_ORDERS_FAILURE";
export const REQUEST_SHARED_UNSYNCED_ORDERS_SUCCESS =
  "REQUEST_SHARED_UNSYNCED_ORDERS_SUCCESS";
export const REQUEST_SHARED_UNSYNCED_ORDERS_FAILURE =
  "REQUEST_SHARED_UNSYNCED_ORDERS_FAILURE";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";
export const UPDATE_ADDRESS_FAILURE = "UPDATE_ADDRESS_FAILURE";
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_FAILURE = "DELETE_ADDRESS_FAILURE";
export const SYNC_PARTICULAR_ORDER_SUCCESS = "SYNC_PARTICULAR_ORDER_SUCCESS";
export const SYNC_PARTICULAR_ORDER_FAILURE = "SYNC_PARTICULAR_ORDER_FAILURE";

// shipments
export const REQUEST_SHIPMENTS_SUCCESS = "REQUEST_SHIPMENTS_SUCCESS";
export const REQUEST_SHIPMENTS_FAILURE = "REQUEST_SHIPMENTS_FAILURE";
export const REQUEST_CARRIERS_SUCCESS = "REQUEST_CARRIERS_SUCCESS";
export const REQUEST_CARRIERS_FAILURE = "REQUEST_CARRIERS_FAILURE";
export const REQUEST_RATES_SUCCESS = "REQUEST_RATES_SUCCESS";
export const REQUEST_RATES_FAILURE = "REQUEST_RATES_FAILURE";
export const CREATE_SHIPMENT_SUCCESS = "CREATE_SHIPMENT_SUCCESS";
export const CREATE_SHIPMENT_FAILURE = "CREATE_SHIPMENT_FAILURE";
export const REQUEST_PACKAGES_SUCCESS = "REQUEST_PACKAGES_SUCCESS";
export const REQUEST_PACKAGES_FAILURE = "REQUEST_PACKAGES_FAILURE";
export const REQUEST_RATES_FOR_ORDER_SUCCESS =
  "REQUEST_RATES_FOR_ORDER_SUCCESS";
export const REQUEST_RATES_FOR_ORDER_FAILURE =
  "REQUEST_RATES_FOR_ORDER_FAILURE";
// Inventory

export const GET_BLANK_PRODUCTS_SUCCESS = "GET_BLANK_PRODUCTS_SUCCESS";
export const GET_BLANK_PRODUCTS_FAILURE = "GET_BLANK_PRODUCTS_FAILURE";
export const GET_BLANK_VARIANTS_SUCCESS = "GET_BLANK_VARIANTS_SUCCESS";
export const GET_BLANK_VARIANTS_FOR_CSV_SUCCESS =
  "GET_BLANK_VARIANTS_FOR_CSV_SUCCESS";
export const GET_BLANK_VARIANTS_FOR_CSV_FAILURE =
  "GET_BLANK_VARIANTS_FOR_CSV_FAILURE";
export const GET_BLANK_VARIANTS_FAILURE = "GET_BLANK_VARIANTS_FAILURE";
export const UPDATE_STOCKS_MANUALLY_SUCCESS = "UPDATE_STOCKS_MANUALLY_SUCCESS";
export const UPDATE_STOCKS_MANUALLY_FAILURE = "UPDATE_STOCKS_MANUALLY_FAILURE";
export const DELETE_STOCKS_MANUALLY_SUCCESS = "DELETE_STOCKS_MANUALLY_SUCCESS";
export const DELETE_STOCKS_MANUALLY_FAILURE = "DELETE_STOCKS_MANUALLY_FAILURE";
export const GET_STOCK_SUCCESS = "GET_STOCK_SUCCESS";
export const GET_STOCK_FAILURE = "GET_STOCK_FAILURE";
export const GET_SUPPLIERS_SUCCESS = "GET_SUPPLIERS_SUCCESS";
export const GET_SUPPLIERS_FAILURE = "GET_SUPPLIERS_FAILURE";
export const ADD_MANUAL_PRODUCT_SUCCESS = "ADD_MANUAL_PRODUCT_SUCCESS";
export const ADD_MANUAL_PRODUCT_FAILURE = "ADD_MANUAL_PRODUCT_FAILURE";

// DASHBOARD

export const REQUEST_PRINTING_STATS_SUCCESS = "REQUEST_PRINTING_STATS_SUCCESS";
export const REQUEST_PRINTING_STATS_FAILURE = "REQUEST_PRINTING_STATS_FAILURE";
export const REQUEST_GRAPH_DATA_SUCCESS = "REQUEST_GRAPH_DATA_SUCCESS";
export const REQUEST_GRAPH_DATA_FAILURE = "REQUEST_GRAPH_DATA_FAILURE";
export const UPDATE_GETTING_STARTED_STEPS = "UPDATE_GETTING_STARTED_STEPS";
export const UPDATE_CURRENT_ACTIVE_STEP = "UPDATE_CURRENT_ACTIVE_STEP";
export const UPDATE_RETAIL_PRODUCT_STEPS = "UPDATE_RETAIL_PRODUCT_STEPS";
export const UPDATE_CUSTOMIZABLE_PRODUCT_STEPS =
  "UPDATE_CUSTOMIZABLE_PRODUCT_STEPS";
export const UPDATE_PRE_PRODUCTION_STEPS = "UPDATE_PRE_PRODUCTION_STEPS";
export const UPDATE_PRODUCTION_STEPS = "UPDATE_PRODUCTION_STEPS";
export const UPDATE_DASHBOARD_TASKS_SUCCESS = "UPDATE_DASHBOARD_TASKS_SUCCESS";
export const UPDATE_DASHBOARD_TASKS_FAILURE = "UPDATE_DASHBOARD_TASKS_FAILURE";
export const UPDATE_GETTING_STARTED_ENTERPRISE_ADMIN_STEPS =
  "UPDATE_GETTING_STARTED_ENTERPRISE_ADMIN_STEPS";

// ANALYTICS SCREEN
export const REQUEST_ANALYTICS_DETAILS_SUCCESS =
  "REQUEST_ANALYTICS_DETAILS_SUCCESS";
export const REQUEST_ANALYTICS_DETAILS_FAILURE =
  "REQUEST_ANALYTICS_DETAILS_FAILURE";
export const REQUEST_ONBOARDING_DETAILS_SUCCESS =
  "REQUEST_ONBOARDING_DETAILS_SUCCESS";
export const REQUEST_ONBOARDING_DETAILS_FAILURE =
  "REQUEST_ONBOARDING_DETAILS_FAILURE";

export const REQUEST_DASHBOARD_TOP_PRODUCTS_SUCCESS =
  "REQUEST_DASHBOARD_TOP_PRODUCTS_SUCCESS";

export const REQUEST_DASHBOARD_TOP_PRODUCTS_FAILURE =
  "REQUEST_DASHBOARD_TOP_PRODUCTS_FAILURE";
// BINS
export const REQUEST_BINS_SUCCESS = "REQUEST_BINS_SUCCESS";
export const REQUEST_BINS_FAILURE = "REQUEST_BINS_FAILURE";
export const CREATE_BIN_SUCCESS = "CREATE_BIN_SUCCESS";
export const CREATE_BIN_FAILURE = "CREATE_BIN_FAILURE";
export const REPRINT_SUCCESS = "REPRINT_SUCCESS";
export const REPRINT_FAILURE = "REPRINT_FAILURE";
export const RENAME_BIN_SUCCESS = "RENAME_BIN_SUCCESS";
export const RENAME_BIN_FAILURE = "RENAME_BIN_FAILURE";
export const MAKE_BIN_AVAILABLE_SUCCESS = "MAKE_BIN_AVAILABLE_SUCCESS";
export const MAKE_BIN_AVAILABLE_FAILURE = "MAKE_BIN_AVAILABLE_FAILURE";
export const REQUEST_BIN_DETAILS_SUCCESS = "REQUEST_BIN_DETAILS_SUCCESS";
export const REQUEST_BIN_DETAILS_FAILURE = "REQUEST_BIN_DETAILS_FAILURE";
export const DELETE_BIN_SUCCESS = "DELETE_BIN_SUCCESS";
export const DELETE_BIN_FAILURE = "DELETE_BIN_FAILURE";
export const CLEAR_BIN_SUCCESS = "CLEAR_BIN_SUCCESS";
export const CLEAR_BIN_FAILURE = "CLEAR_BIN_FAILURE";

// Order Routing
export const REQUEST_ENTERPRISES_SUCCESS = "REQUEST_ENTERPRISES_SUCCESS";
export const REQUEST_ENTERPRISES_FAILURE = "REQUEST_ENTERPRISES_FAILURE";
export const CREATE_ORDER_ROUTING_SUCCESS = "CREATE_ORDER_ROUTING_SUCCESS";
export const CREATE_ORDER_ROUTING_FAILURE = "CREATE_ORDER_ROUTING_FAILURE";
export const PARTIAL_ORDER_ROUTING_SUCCESS = "PARTIAL_ORDER_ROUTING_SUCCESS";
export const PARTIAL_ORDER_ROUTING_FAILURE = "PARTIAL_ORDER_ROUTING_FAILURE";

// Forget Password
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_ERROR = "FORGET_PASSWORD_ERROR";

// common
export const SET_INSTRUCTION_STEPS = "SET_INSTRUCTION_STEPS";
export const UNSET_INSTRUCTION_STEPS = "UNSET_INSTRUCTION_STEPS";
export const SET_ALERT = "SET_ALERT";
export const UNSET_ALERT = "UNSET_ALERT";
export const SET_CIRCULAR_PROGRESS = "SET_CIRCULAR_PROGRESS";

// credentials
export const GET_PAYTMENT_METHODS_SUCCESS = "GET_PAYTMENT_METHODS_SUCCESS";
export const GET_PAYTMENT_METHODS_FAILURE = "GET_PAYTMENT_METHODS_FAILURE";
export const SET_DEFAULT_PAYMENT_METHOD_SUCCESS =
  "SET_DEFAULT_PAYMENT_METHOD_SUCCESS";
export const SET_DEFAULT_PAYMENT_METHOD_FAILURE =
  "SET_DEFAULT_PAYMENT_METHOD_FAILURE";
export const GET_PAYTMENT_METHODS_LOADING = "GET_PAYTMENT_METHODS_LOADING";

// pricings
export const REQUEST_PRICING_SUCCESS = "REQUEST_PRICING_SUCCESS";
export const REQUEST_PRICING_FAILURE = "REQUEST_PRICING_FAILURE";
export const UPGRADE_PLAN_SUCCESS = "UPGRADE_PLAN_SUCCESS";
export const UPGRADE_PLAN_FAILURE = "UPGRADE_PLAN_FAILURE";

// invoices
export const REQUEST_USERS_TO_INVOICE_SUCCESS =
  "REQUEST_USERS_TO_INVOICE_SUCCESS";
export const REQUEST_USERS_TO_INVOICE_FAILURE =
  "REQUEST_USERS_TO_INVOICE_FAILURE";
export const REQUEST_INVOICES_OF_USER_SUCCESS =
  "REQUEST_INVOICES_OF_USER_SUCCESS";
export const REQUEST_INVOICES_OF_USER_FAILURE =
  "REQUEST_INVOICES_OF_USER_FAILURE";
export const GENERATE_INVOICE_FOR_USER_SUCCESS =
  "GENERATE_INVOICE_FOR_USER_SUCCESS";
export const GENERATE_INVOICE_FOR_USER_FAILURE =
  "GENERATE_INVOICE_FOR_USER_FAILURE";
export const GET_INVOICE_DETAILS_SUCCESS = "GET_INVOICE_DETAILS_SUCCESS";
export const GET_INVOICE_DETAILS_FAILURE = "GET_INVOICE_DETAILS_FAILURE";
export const SEND_INVOICE_TO_USER_SUCCESS = "SEND_INVOICE_TO_USER_SUCCESS";
export const SEND_INVOICE_TO_USER_FAILURE = "SEND_INVOICE_TO_USER_FAILURE";
export const EDIT_INVOICE_SUCCESS = "EDIT_INVOICE_SUCCESS";
export const EDIT_INVOICE_FAILURE = "EDIT_INVOICE_FAILURE";
export const MARK_AS_PAID_SUCCESS = "MARK_AS_PAID_SUCCESS";
export const MARK_AS_PAID_FAILURE = "MARK_AS_PAID_FAILURE";
export const REQUEST_CURRENT_USER_INVOICES_SUCCESS =
  "REQUEST_CURRENT_USER_INVOICES_SUCCESS";
export const REQUEST_CURRENT_USER_INVOICES_FAILURE =
  "REQUEST_CURRENT_USER_INVOICES_FAILURE";
export const REQUEST_PAYOUTS_SUCCESS = "REQUEST_PAYOUTS_SUCCESS";
export const REQUEST_PAYOUTS_FAILURE = "REQUEST_PAYOUTS_FAILURE";

// external service
export const GET_EMBROIDERY_IMAGE_FAILURE = "GET_EMBROIDERY_IMAGE_FAILURE";
export const GET_EMBROIDERY_IMAGE_SUCCESS = "GET_EMBROIDERY_IMAGE_SUCCESS";

// services
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";
export const GET_ART_IMAGES_SUCCESS = "GET_ART_IMAGES_SUCCESS";
export const GET_ART_IMAGES_FAILURE = "GET_ART_IMAGES_FAILURE";
export const GET_ART_IMAGE_DETAILS_SUCCESS = "GET_ART_IMAGE_DETAILS_SUCCESS";
export const GET_ART_IMAGE_DETAILS_FAILURE = "GET_ART_IMAGE_DETAILS_FAILURE";

// enterprise domains
export const CREATE_ENTERPRISE_DOMAIN_SUCCESS =
  "CREATE_ENTERPRISE_DOMAIN_SUCCESS";
export const CREATE_ENTERPRISE_DOMAIN_FAILURE =
  "CREATE_ENTERPRISE_DOMAIN_FAILURE";
export const UPDATE_ENTERPRISE_DOMAIN_SUCCESS =
  "UPDATE_ENTERPRISE_DOMAIN_SUCCESS";
export const UPDATE_ENTERPRISE_DOMAIN_FAILURE =
  "UPDATE_ENTERPRISE_DOMAIN_FAILURE";

// enterprises
export const GET_ENTERPRISE_ADMINS_SUCCESS = "GET_ENTERPRISE_ADMINS_SUCCESS";
export const GET_ENTERPRISE_ADMINS_FAILURE = "GET_ENTERPRISE_ADMINS_FAILURE";
export const GET_ENTERPRISE_USERS_SUCCESS = "GET_ENTERPRISE_USERS_SUCCESS";
export const GET_ENTERPRISE_USERS_FAILURE = "GET_ENTERPRISE_USERS_FAILURE";
